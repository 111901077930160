import { CircularProgress } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { SCALEOPS_COLORS } from "../../colors";
import { getDataGridSx } from "../../utils/styleUtils";
import { KeyValTooltipOption } from "../../utils/tooltipUtils";
import ProgressBar from "../ProgressBar";
import SwitchBox, { THEMES } from "../SwitchBox";
import { default as Tooltip } from "../Tooltip";
import { ActionCell } from "./Action";
import { UevictableTableRowType } from "./Utils";
import { useAutomatePods } from "./useAutomatePods";

interface Props {
  rows?: UevictableTableRowType[];
  isLoading: boolean;
}

enum Columns {
  Action = "Action",
  SavingsAvailable = "Savings Available",
  Progress = "Automated %",
  Automate = "Automate",
}

const DEFAULT_COL_PROPS: Partial<GridColDef> = {
  flex: 1,
  minWidth: 30,
  type: "string",
  align: "center",
  headerAlign: "center",
  disableColumnMenu: true,
  sortable: true,
  cellClassName: "h-[1000px]",
};

const getColumns = (): GridColDef[] => {
  return [
    {
      field: "action",
      headerName: Columns.Action,
      headerAlign: "center",
      flex: 2.1,
      minWidth: 400,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        const action = params.row.action;
        return (
          <div className="w-full">
            <ActionCell title={action.title} icon={action.icon} description={action.description} />
          </div>
        );
      },
    },
    {
      ...DEFAULT_COL_PROPS,
      field: "savingAvailable",
      headerName: Columns.SavingsAvailable,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        return (
          <div className="text-main-green">
            {"$"}
            {params.row.savingAvailable}
          </div>
        );
      },
    },
    {
      ...DEFAULT_COL_PROPS,
      field: "optimizedPods",
      headerName: Columns.Progress,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        if (!params.row.optimizedPods || !params.row.totalPods) {
          return null;
        }

        const optimizedPods = Number(params.row.optimizedPods);
        const totalPods = Number(params.row.totalPods);

        let percentageOfoptimizedPods = Math.round((optimizedPods / totalPods) * 100);
        switch (true) {
          case percentageOfoptimizedPods > 100:
            percentageOfoptimizedPods = 100;
            break;
          case percentageOfoptimizedPods < 0 || isNaN(percentageOfoptimizedPods):
            percentageOfoptimizedPods = 0;
            break;
        }

        const tooltipContent = (
          <div>
            <KeyValTooltipOption
              name="Optimized"
              value={params.row.optimizedPods}
              background={SCALEOPS_COLORS.main.green}
            />
            <KeyValTooltipOption
              name="Unoptimized"
              value={params.row.totalPods - params.row.optimizedPods}
              background={SCALEOPS_COLORS.main.info}
            />
          </div>
        );
        return (
          <Tooltip title={tooltipContent}>
            <ProgressBar percentage={percentageOfoptimizedPods} mainFill={SCALEOPS_COLORS.main.green} />
          </Tooltip>
        );
      },
    },
    {
      ...DEFAULT_COL_PROPS,
      field: "isAutomated",
      headerName: Columns.Automate,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        return (
          <div className="flex items-center gap-2">
            <div className="relative">
              <SwitchBox
                title="AUTOMATE"
                handleClick={() => {
                  const setAutomate = useAutomatePods();
                  //TODO: Fix this
                  console.log("AUTOMATE CLICKED ", params.row.id, params.row.isAutomated);
                  setAutomate.mutate({
                    [params.row.id]: !params.row.isAutomated,
                  });
                }}
                checked={!!params.value}
                theme={THEMES.Purple}
              />
            </div>
          </div>
        );
      },
    },
  ];
};

const UevictableTable = ({ rows, isLoading }: Props) => {
  if (isLoading && !rows) {
    return (
      <div className="w-full h-[60vh] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <DataGrid
      sx={{
        ...getDataGridSx(),
      }}
      rows={rows || []}
      columns={getColumns()}
      autoHeight={true}
      rowHeight={120}
      loading={isLoading}
      disableSelectionOnClick
      hideFooter
    />
  );
};

export default UevictableTable;
