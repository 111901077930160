import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, Switch, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowModel } from "@mui/x-data-grid";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import LockIcon from "../../../Icons/LockIcon";
import { DeleteOverriddenWorkloadsProperties } from "../../../WorkloadsContext";
import { ScaleOpsClient } from "../../../api/api";
import { GetDashboardByNamespaceResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import useResetRecommendation from "../../../pages/Overview/PolicyTuning/mutation/useResetRecommendation";
import useUpdateAutomation from "../../../pages/Overview/PolicyTuning/mutation/useUpdateAutomation";
import { FeatureEnabled } from "../../../utils/FeaturesHelper";
import { ApplyRecommendation } from "../../../utils/configFetcherUtils";
import { isGitSyncEnabled } from "../../../utils/configUtils";
import { SWITCH_SX } from "../../../utils/styleUtils";
import Tooltip from "../../Tooltip";
import YouHaveReadOnlyAccess from "../../YouHaveReadOnlyAccess";
import AutomateYourFirstWorkloadTooltip from "../AnimateYourFirstWorkloadTooltip";
import AutomationTooltip from "../AutomationTooltip";
import useAttachPolicyToWorkload from "../useAttachPolicyToWorkload";
import AutomationButtonDisabledTooltip from "./AutomationButtonDisabledTooltip";
import { isUnallocatedRow, SnackbarProps } from "./GridColumnsUtils";

const ENABLE_FIRST_ANIMATION_CTA_FF = true;
const ACTIVE_TOGGLE_PING_SIZE = 10;

enum WorkloadMenuOption {
  RESTORE_TO_SUGGESTED_POLICY = "Restore to suggested policy",
}
export interface AutomatedColumnProps {
  api: ScaleOpsClient;
  setSnackbar: (snackbar: SnackbarProps) => void;
  setGitWorkflow: (workflow: components["schemas"]["UtilsWorkload"] | undefined) => void;
  deleteOverriddenWorkloadsProperties: (props: DeleteOverriddenWorkloadsProperties) => void;
  attachPolicyToWorkload: ReturnType<typeof useAttachPolicyToWorkload>;
  resetRecommendation: ReturnType<typeof useResetRecommendation>;
  updateAutomation: ReturnType<typeof useUpdateAutomation>;
  isReadOnlyFrontEnd: boolean;
  wasInstalledInTheLast3Days: boolean;
  firstRowId: string | undefined;
  didUserAutomateFirstWorkload: boolean;
  setDidUserAutomateFirstWorkload: (value: boolean) => void;
  data: GetDashboardByNamespaceResponse | undefined;
}

export default function AutomatedColumn({
  params,
  api,
  setSnackbar,
  setGitWorkflow,
  deleteOverriddenWorkloadsProperties,
  attachPolicyToWorkload,
  resetRecommendation,
  updateAutomation,
  isReadOnlyFrontEnd,
  wasInstalledInTheLast3Days,
  firstRowId,
  didUserAutomateFirstWorkload,
  setDidUserAutomateFirstWorkload,
  data,
}: {
  params: GridRenderCellParams<boolean, components["schemas"]["UtilsWorkload"], boolean>;
} & AutomatedColumnProps) {
  const stopPropagationFnc = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: { message }, severity: "error" });
  }, []);

  const handleProcessRowUpdateSuccess = useCallback((message: string | React.ReactNode) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  const restartInBulk = (workloads: { name: string; type: string; namespace: string }[]): Promise<void> => {
    const request: components["schemas"]["RolloutInput"] = {
      workloads: workloads,
    };
    return api
      .getFetcher()
      .path("/api/v1/recommendation/rollout")
      .method("post")
      .create()(request)
      .then(() => {
        toast.success("Successfully rolled out workloads", {
          position: "top-center",
        });
      })
      .catch((reason) => {
        toast.error("Failed to rollout workloads", {
          position: "top-center",
        });
        console.error(reason);
      });
  };

  const patchWorkload = (
    row: GridRowModel<components["schemas"]["UtilsWorkload"]>,
    recommendationType = "rightSize"
  ) => {
    ApplyRecommendation(row.namespace, row.workloadName, row.type, recommendationType)
      .then((response) => {
        handleProcessRowUpdateSuccess(
          response.ok ? `Successfully Patch ${row.workloadName}` : `Failed to Patch ${row.workloadName}`
        );
      })
      .catch((error) => {
        console.error(error);
        handleProcessRowUpdateError("Failed to patch workload");
      });
  };

  const getWorkloadActionMenu = (row: components["schemas"]["UtilsWorkload"], type?: string) => {
    // const updateAutomationExclude = useUpdateAutomationExclude();

    if (isUnallocatedRow(row)) return <div></div>;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleActionClick = (
      row: GridRowModel<components["schemas"]["UtilsWorkload"]>,
      event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
    ) => {
      if (event.currentTarget.innerText === "Apply recommendation") {
        patchWorkload(row);
      }

      if (event.currentTarget.innerText === "Auto update in Git") {
        setGitWorkflow(row);
      }

      if (event.currentTarget.innerText === "Revert recommendation") {
        patchWorkload(row, "undoRightSize");
      }

      if (event.currentTarget.innerText === "Rollout workload") {
        restartInBulk([{ name: row.workloadName, type: row.type, namespace: row.namespace }]).then(() =>
          console.log("")
        );
      }
      if (event.currentTarget.innerText === WorkloadMenuOption.RESTORE_TO_SUGGESTED_POLICY) {
        deleteOverriddenWorkloadsProperties({
          ids: [row.id],
          propertyNames: ["policyName"],
        });
        const updateValue = async () => {
          attachPolicyToWorkload.mutate({
            name: row.smartPolicyName || "production",
            target: `${row.type}-${row.workloadName}`.toLocaleLowerCase(),
            namespaceName: row.namespace,
            id: row.id,
            oldPolicyName: row.policyName,
          });

          await resetRecommendation.mutateAsync({
            name: `${row.type.toLowerCase()}-${row.workloadName}`,
            namespace: row.namespace,
            id: row.id,
            dontShowToast: true,
          });
        };
        updateValue();
      }

      setAnchorEl(null);
    };

    const showExtensiveOptions =
      type &&
      ["Deployment", "DaemonSet", "StatefulSet", "DeploymentConfig", "flink"]
        .map((s) => s.toLocaleLowerCase())
        .includes(type.toLocaleLowerCase());

    const optionsToShow = [
      FeatureEnabled("DemoVersion") ? "Apply recommendation" : undefined,
      FeatureEnabled("DemoVersion") ? "Revert recommendation" : undefined,
      showExtensiveOptions ? "Rollout workload" : undefined,
      showExtensiveOptions && isGitSyncEnabled() ? "Auto update in Git" : undefined,
      WorkloadMenuOption.RESTORE_TO_SUGGESTED_POLICY,
    ].filter((option) => option !== undefined);

    if (!optionsToShow || optionsToShow.length === 0) return null;

    const disableMenuOpen = row.isPrivileged == false || row.isReadyRecommendation == false;

    return (
      <div className="flex items-center justify-center w-full h-full" onClick={stopPropagationFnc}>
        <div className="flex">
          <div className="w-fit opacity-40 hover:opacity-100">
            <div
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                if (disableMenuOpen) return false;
                handleClick(e);
              }}
              className={clsx({
                "text-text-disable cursor-not-allowed": disableMenuOpen,
              })}
            >
              <MoreVertIcon />
            </div>
          </div>
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {isReadOnlyFrontEnd && <YouHaveReadOnlyAccess className="my-2 mx-3" chipDesign />}
          {optionsToShow.map((option) => {
            const disableRestoreSuggestedPolicyForWorkloadsWithForcedPolicy =
              row.isPolicyForced && option === WorkloadMenuOption.RESTORE_TO_SUGGESTED_POLICY;

            return (
              <MenuItem
                key={option}
                disabled={
                  isReadOnlyFrontEnd ||
                  (option == "Apply recommendation" && !row.isEditable) ||
                  disableRestoreSuggestedPolicyForWorkloadsWithForcedPolicy
                }
                onClick={(event) => handleActionClick(row, event)}
              >
                <Typography variant="body2">{option}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  };

  const isDisabled =
    isReadOnlyFrontEnd ||
    params.row.isPrivileged == false ||
    params.row.isReadyRecommendation == false ||
    !params.row.isEditable;

  const isFirstRowAndZeroPercentAutomation =
    ENABLE_FIRST_ANIMATION_CTA_FF &&
    wasInstalledInTheLast3Days &&
    params.row.isReadyRecommendation &&
    params.row.id === firstRowId &&
    !params.row.auto &&
    data?.totalNamespaceSummary?.automatedWorkloads === 0 &&
    !didUserAutomateFirstWorkload &&
    !isDisabled;

  const isAutoForced = params.row.isAutoForced;
  const isAutomationDisabled = params.row.namespace === "scaleops-system";
  const isAutomationExcluded = params.row.isAutomationExcluded || isAutomationDisabled;

  const onChange = (_: React.ChangeEvent<HTMLInputElement> | undefined, checked: boolean) => {
    setDidUserAutomateFirstWorkload(true);
    updateAutomation.mutate({
      id: params.row.id,
      namespace: params.row.namespace,
      workloadName: params.row.workloadName,
      workloadType: params.row.type,
      state: checked,
    });
  };

  const isEditable = params.row.isEditable;

  let tooltipContent: JSX.Element;

  switch (true) {
    case isFirstRowAndZeroPercentAutomation:
      tooltipContent = <AutomateYourFirstWorkloadTooltip />;
      break;
    case !isEditable:
      tooltipContent = (
        <Typography variant="caption">
          You don't have <b>permission to automate</b> this workload.
        </Typography>
      );
      break;
    default:
      tooltipContent = (
        <AutomationTooltip
          details={params.row?.rollingStrategyDetails}
          replicas={params.row?.replicas}
          hasHap={!!params.row?.hpa}
          hasVpa={!!params.row?.hasVpa}
        />
      );
      break;
  }

  if (isUnallocatedRow(params.row)) return <div></div>;
  return (
    <div onClick={stopPropagationFnc} className="w-full h-full flex justify-center align-center">
      <Tooltip
        title={!params.row.isPrivileged ? "Actions for unlimited workloads are limited to ScaleOps full version" : ""}
        disabled={params.row.isPrivileged}
      >
        <div className="flex justify-end w-full items-center">
          <div className="flex gap-2 items-center justify-center">
            {isAutomationExcluded || isAutoForced ? (
              <Tooltip title={<AutomationButtonDisabledTooltip params={params.row} />} placement="left" maxWidth={500}>
                <div className="block">
                  <div className="opacity-30">
                    <Switch sx={{ float: "center" }} checked={params.row.auto} />
                  </div>
                  {!isAutomationDisabled && (
                    <div className="w-full flex justify-center items-center mt-[-2rem] h-[1.875rem] black">
                      <LockIcon />
                    </div>
                  )}
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                title={tooltipContent}
                placement="left"
                maxWidth={800}
                isTourGuideTooltip={isFirstRowAndZeroPercentAutomation}
              >
                <div className="relative">
                  <Switch
                    data-testid="workload-automation-switch"
                    sx={{
                      ...SWITCH_SX(),
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    disabled={isDisabled}
                    checked={params.row.auto}
                    onChange={onChange}
                  />
                  {isFirstRowAndZeroPercentAutomation && (
                    <div
                      className="absolute top-[.5625rem] left-[.5625rem] w-[1.25rem] h-[1.25rem] rounded-full flex items-center justify-center pt-[.0313rem] pl-[.0313rem]"
                      onClick={() => {
                        onChange(undefined, true);
                      }}
                    >
                      <span
                        className="custom-animate-ping duration-1050 absolute inline-flex rounded-full bg-main-blue opacity-50"
                        style={{
                          width: ACTIVE_TOGGLE_PING_SIZE * 2,
                          height: ACTIVE_TOGGLE_PING_SIZE * 2,
                        }}
                      />
                      <span
                        className="absolute inline-flex rounded-full bg-main-blue opacity-75"
                        style={{
                          width: ACTIVE_TOGGLE_PING_SIZE,
                          height: ACTIVE_TOGGLE_PING_SIZE,
                        }}
                      />
                    </div>
                  )}
                </div>
              </Tooltip>
            )}
          </div>
          {!isAutomationDisabled && isEditable ? (
            <div className="w-[2.5rem] h-[2.5rem] flex">{getWorkloadActionMenu(params.row, params.row.type)}</div>
          ) : (
            <div className="w-[31px] flex justify-center opacity-10 hover:cursor-not-allowed">
              <MoreVertIcon />
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
}
