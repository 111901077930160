import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import pluralize from "pluralize";
import { ArrayParam, BooleanParam, useQueryParam } from "use-query-params";
import AutomationIcon from "../../Icons/AutomationIcon";
import PolicyIcon from "../../Icons/PolicyIcon";
import RestoreIcon from "../../Icons/RestoreIcon";
import RolloutIcon from "../../Icons/RolloutIcon";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import UnAutomateIcon from "../../Icons/UnAutomateIcon";
import { GetClusterPermissions, GetClusterPermissionsResponse } from "../../api/fetcher";
import { components } from "../../api/schema";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import Button from "../Button";
import YouHaveReadOnlyAccess from "../YouHaveReadOnlyAccess";
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import SelectActionItem from "./SelectActionItem";
import usePostAutoCluster from "./usePostAutoCluster";

const AutomateSpan = ({ animate }: { animate: boolean }) => (
  <span className="flex items-center gap-2">
    Automate <ScaleopsIcon className={clsx({ "info-icon-animation": animate })} />
  </span>
);

const { queryKey, queryFn } = GetClusterPermissions();
interface Props {
  selectedRows: components["schemas"]["UtilsWorkload"][] | undefined;
  numberOfSelectedWorkloadsExcludedFromAutomation: number;
  policies: components["schemas"]["V1alpha1Policy"][];
  automateWorkloads: (state: boolean) => void;
  attachPolicyToWorkloadsInBulk: (policyName: string) => void;
  defaultSelectedPolicyName: string | undefined;
  restoreAllPolicyInBulk: (selectedRows?: components["schemas"]["UtilsWorkload"][]) => void;
  restartWorkloadsBulk: (selectedRows?: components["schemas"]["UtilsWorkload"][]) => void;
  areNonNamespaceFiltersApplied: boolean;
  automateByFilter: (state: boolean) => void;
  attachPolicyToNamespace: (policyName: string) => void;
  attachPolicyToCluster: (policyName: string) => void;
  restoreClusterPolicy: () => void;
  restoreNamespacesPolicy: () => void;
  numberOfSelectedIsAutoForcedWorkloads: number;
  numberOfSelectedForcedPolicyWorkloads: number;
}

const BulkActions = ({
  selectedRows,
  numberOfSelectedWorkloadsExcludedFromAutomation,
  policies,
  automateWorkloads,
  attachPolicyToWorkloadsInBulk,
  defaultSelectedPolicyName,
  restoreAllPolicyInBulk,
  restartWorkloadsBulk,
  areNonNamespaceFiltersApplied,
  automateByFilter,
  attachPolicyToNamespace,
  attachPolicyToCluster,
  restoreClusterPolicy,
  restoreNamespacesPolicy,
  numberOfSelectedIsAutoForcedWorkloads,
  numberOfSelectedForcedPolicyWorkloads,
}: Props) => {
  const postAutoCluster = usePostAutoCluster();
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const { data } = useQuery<GetClusterPermissionsResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  const [namespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [isNamespacesExclude] = useQueryParam("isNamespacesExclude", BooleanParam);
  const [namespaceAnnotationParams] = useQueryParam("namespaceAnnotation", ArrayParam);

  const numberOfSelectedNamespaces = namespacesParams?.length ?? 0;
  const numberOfSelectedNamespaceAnnotations = namespaceAnnotationParams?.length ?? 0;
  const scaleopsSystemSelected = namespacesParams?.includes("scaleops-system");
  const onlyScaleopsSystem = numberOfSelectedNamespaces === 1 && scaleopsSystemSelected;

  const numberOfSelectedRows = selectedRows?.length || 0;
  const areWorkloadsActionsDisabled = isReadyOnlyFrontEnd || !numberOfSelectedRows;
  const areClusterActionsDisabled = isReadyOnlyFrontEnd || !data?.CanAutomateCluster;

  const areNamespacesActionsDisabled =
    isReadyOnlyFrontEnd ||
    onlyScaleopsSystem ||
    (!!isNamespacesExclude && !!namespacesParams && !!namespacesParams.length) ||
    areNonNamespaceFiltersApplied ||
    (!numberOfSelectedNamespaces && !numberOfSelectedNamespaceAnnotations);

  const isNamespaceMenuIsOpenByDefault =
    !numberOfSelectedRows &&
    (!!numberOfSelectedNamespaces || !!numberOfSelectedNamespaceAnnotations) &&
    !areNonNamespaceFiltersApplied;

  const areAllSelectedRowsExcludedFromAutomation =
    numberOfSelectedRows === numberOfSelectedWorkloadsExcludedFromAutomation;

  const isWorkloadAutomationDisabled =
    areWorkloadsActionsDisabled ||
    numberOfSelectedWorkloadsExcludedFromAutomation === numberOfSelectedRows ||
    areAllSelectedRowsExcludedFromAutomation ||
    numberOfSelectedIsAutoForcedWorkloads === numberOfSelectedRows;

  const workloadActionsCategoryDescription: React.ReactNode = (
    <div>
      {!numberOfSelectedRows && (
        <div>
          <b>
            Select <span className="text-primary-purpleBlue">workloads</span> to take action.
          </b>
        </div>
      )}
      {!!numberOfSelectedRows && (
        <div>
          <b>
            <span className="text-primary-purpleBlue">
              {numberOfSelectedRows} {pluralize("workload", numberOfSelectedRows ? numberOfSelectedRows : 1)}
            </span>{" "}
            selected.
          </b>
        </div>
      )}
      {!!numberOfSelectedWorkloadsExcludedFromAutomation && (
        <div>
          <span>
            <b>Note:</b> Some of the selected workloads are <b>excluded from automation</b>.
          </span>
        </div>
      )}
      {!!numberOfSelectedIsAutoForcedWorkloads && (
        <div>
          <span>
            <b>Note:</b> Some of the selected workloads have <b>forced automation</b>.
          </span>
        </div>
      )}
      {!!numberOfSelectedForcedPolicyWorkloads && (
        <div>
          <span>
            <b>Note:</b> Some of the selected workloads have <b>forced policy</b>.
          </span>
        </div>
      )}
    </div>
  );

  let namespaceActionsCategoryDescription: React.ReactNode | undefined;

  switch (true) {
    case areNonNamespaceFiltersApplied:
      namespaceActionsCategoryDescription = (
        <p>
          <b>
            <span className="text-primary-purpleBlue">{numberOfSelectedNamespaces} namespace</span> selected.
          </b>
          <br />
          Remove all filters <b>except namespace filter</b> to take action.
        </p>
      );
      break;
    case !!onlyScaleopsSystem:
      namespaceActionsCategoryDescription = (
        <p>
          <b>Note:</b> you have filtered <b>scaleops-system</b> namespaces.
          <br />
          <b className="text-primary-purpleBlue">deselects scaleops-system</b> to take action.
        </p>
      );
      break;
    case !!isNamespacesExclude && !!namespacesParams && !!namespacesParams.length:
      namespaceActionsCategoryDescription = (
        <p>
          <b>Note:</b> you have selected <b>Exclude Namespaces</b> filter.
          <br />
          <b>
            Select <span className="text-primary-purpleBlue">Include Namespaces</span> filter
          </b>{" "}
          to take action.
        </p>
      );
      break;
    case !!numberOfSelectedNamespaces || !!numberOfSelectedNamespaceAnnotations:
      namespaceActionsCategoryDescription = (
        <p>
          <b>
            {!!numberOfSelectedNamespaces && (
              <>
                <span className="text-primary-purpleBlue">
                  {onlyScaleopsSystem ? "scaleops-system" : numberOfSelectedNamespaces}{" "}
                  {pluralize("namespace", numberOfSelectedNamespaces)}
                </span>{" "}
                {!!numberOfSelectedNamespaces && !!numberOfSelectedNamespaceAnnotations ? "and," : "selected."}
              </>
            )}
            {!!numberOfSelectedNamespaces && !!numberOfSelectedNamespaceAnnotations && <br />}
            {!!numberOfSelectedNamespaceAnnotations && (
              <>
                <span className="text-primary-purpleBlue">
                  {numberOfSelectedNamespaceAnnotations}{" "}
                  <span className="scaleopsTextShadow">
                    namespace {pluralize("annotation", numberOfSelectedNamespaceAnnotations)}
                  </span>{" "}
                </span>
                selected.
              </>
            )}
          </b>
          <br />
          <span className="text-[10px]">
            This action is applicable for all <b>selected and future</b> workloads.
          </span>
        </p>
      );
      break;
    default:
      namespaceActionsCategoryDescription = (
        <p>
          <b>
            Select <span className="text-primary-purpleBlue">namespaces</span> to take action.
          </b>
          <br />
          <span className="text-[10px]">
            This action is applicable for all <b>selected and future</b> workloads.
          </span>
        </p>
      );
  }

  return (
    <>
      <DropdownMenu
        minMenuHeight={isReadyOnlyFrontEnd ? 450 : 420}
        preMenuCategorySection={isReadyOnlyFrontEnd && <YouHaveReadOnlyAccess chipDesign />}
        button={
          <Button
            label={<span style={{ fontWeight: 700 }}>Actions</span>}
            className="scaleopsShadow min-w-[100px] h-[32px]"
            variant="smallGreen"
          />
        }
        categories={[
          {
            categoryName: "Workload actions",
            categoryDescription: {
              content: workloadActionsCategoryDescription,
            },
            isOpenByDefault: !isNamespaceMenuIsOpenByDefault,
            options: [
              {
                optionName: <AutomateSpan animate={!isWorkloadAutomationDisabled} />,
                onClick: () => {
                  automateWorkloads(true);
                },
                isDisabled: isWorkloadAutomationDisabled,
                icon: <AutomationIcon />,
                // emphasize: true,
              },
              {
                optionName: "Un-Automate",
                onClick: () => {
                  automateWorkloads(false);
                },
                isDisabled: isWorkloadAutomationDisabled,
                icon: <UnAutomateIcon />,
              },
              {
                optionName: (
                  <SelectActionItem
                    options={
                      policies
                        .map((policy) => policy.metadata?.name)
                        .filter((policy) => policy !== undefined) as string[]
                    }
                    onChange={attachPolicyToWorkloadsInBulk}
                    defaultValue={defaultSelectedPolicyName}
                    disabled={areWorkloadsActionsDisabled}
                    preSelectText="Attach policy to "
                  />
                ),
                onClick: () => true,
                isDisabled:
                  areWorkloadsActionsDisabled || numberOfSelectedForcedPolicyWorkloads === numberOfSelectedRows,
                icon: <PolicyIcon />,
              },
              {
                optionName: "Restore suggested policy",
                onClick: () => restoreAllPolicyInBulk(selectedRows),
                isDisabled:
                  areWorkloadsActionsDisabled || numberOfSelectedForcedPolicyWorkloads === numberOfSelectedRows,
                icon: (
                  <div className="ml-[-2px] pr-[2px]">
                    <RestoreIcon />
                  </div>
                ),
              },
              {
                optionName: "Rollout",
                onClick: () => restartWorkloadsBulk(selectedRows),
                isDisabled: areWorkloadsActionsDisabled,
                icon: <RolloutIcon />,
              },
            ],
          },
          {
            categoryName: "Namespace actions",
            categoryDescription: {
              content: namespaceActionsCategoryDescription,
            },
            isOpenByDefault: isNamespaceMenuIsOpenByDefault,
            options: [
              {
                optionName: <AutomateSpan animate={!areNamespacesActionsDisabled} />,
                onClick: () => {
                  automateByFilter(true);
                },
                isDisabled: areNamespacesActionsDisabled,
                icon: <AutomationIcon />,
                // emphasize: true,
              },
              {
                optionName: "Un-Automate",
                onClick: () => {
                  automateByFilter(false);
                },
                isDisabled: areNamespacesActionsDisabled,
                icon: <UnAutomateIcon />,
              },
              {
                optionName: (
                  <SelectActionItem
                    options={
                      policies
                        .map((policy) => policy.metadata?.name)
                        .filter((policy) => policy !== undefined) as string[]
                    }
                    onChange={(policyName) => attachPolicyToNamespace(policyName)}
                    preSelectText="Attach policy "
                    postSelectText=""
                  />
                ),
                onClick: () => true,
                isDisabled: areNamespacesActionsDisabled,
                icon: <PolicyIcon />,
              },
              {
                optionName: "Restore suggested policy",
                onClick: () => restoreNamespacesPolicy(),
                isDisabled: areNamespacesActionsDisabled,
                icon: (
                  <div className="ml-[-2px] pr-[2px]">
                    <RestoreIcon />
                  </div>
                ),
              },
            ],
          },
          {
            categoryName: "Cluster actions",
            categoryDescription: {
              content: (
                <p>
                  <b>
                    Set automation <span className="text-primary-purpleBlue">for the entire cluster</span>.
                  </b>
                  <br />
                  {data?.CanAutomateCluster ? (
                    <span className="text-[10px]">
                      This action is applicable for all <b>current and future</b> workloads.
                    </span>
                  ) : (
                    <span className="text-[10px]">
                      <b>Note:</b> you <b>don't have permission</b> to automate the cluster.
                    </span>
                  )}
                </p>
              ),
            },
            isOpenByDefault: false,
            options: [
              {
                onClick: () => postAutoCluster.mutate({ state: true }),
                optionName: <AutomateSpan animate={areClusterActionsDisabled} />,
                icon: <AutomationIcon />,
                isDisabled: areClusterActionsDisabled,
              },
              {
                optionName: "Un-Automate",
                onClick: () => postAutoCluster.mutate({ state: false }),
                icon: <UnAutomateIcon />,
                isDisabled: areClusterActionsDisabled,
              },
              {
                optionName: (
                  <SelectActionItem
                    options={
                      policies
                        .map((policy) => policy.metadata?.name)
                        .filter((policy) => policy !== undefined) as string[]
                    }
                    onChange={(policyName) => attachPolicyToCluster(policyName)}
                    preSelectText="Attach policy "
                    postSelectText=""
                  />
                ),
                onClick: () => true,
                isDisabled: areClusterActionsDisabled,
                icon: <PolicyIcon />,
              },
              {
                optionName: "Restore suggested policy",
                onClick: () => restoreClusterPolicy(),
                isDisabled: areClusterActionsDisabled,
                icon: (
                  <div className="ml-[-2px] pr-[2px]">
                    <RestoreIcon />
                  </div>
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default BulkActions;
