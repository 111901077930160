import clsx from "clsx";
import { memo } from "react";
import PartialBorders from "../../components/PartialBorders";
import RunningNumberBox from "../../pages/Overview/TopOverviewSection/RunningNumberBox";
import WasteVsOptimizedBarCharts from "../../pages/Overview/TopOverviewSection/WasteVsOptimizedBarCharts";
import { GRAY_SIDE_TEXT, MAIN_SECTION_CLASSNAME } from "../../utils/styleUtils";
import PercentagePieChart from "../PercentagePieChart";
//import useWindowSize from "../useWindowSize";

const MAIN_GAP = "gap-4";
const MAIN_WIDTH = "w-[25%]";
const CENTER_FLEX_ITEMS = "flex flex-col justify-center items-center";

interface Props {
  monthlyCost: number;
  totalOptimizedPods: number;
  totalUnOptimizedPods: number;
  wastedSpend: number;
  blockedNodes: number;
  disableAnimation?: boolean;
  isLoading?: boolean;
}

const TopSection: React.FC<Props> = memo(
  ({
    monthlyCost,
    totalOptimizedPods,
    totalUnOptimizedPods,
    wastedSpend,
    blockedNodes,
    disableAnimation,
    isLoading = false,
  }: Props) => {
    //const size = useWindowSize(); TODO: remove if not used
    return (
      <div className="flex gap-2">
        <div className={MAIN_SECTION_CLASSNAME}>
          <div className={MAIN_WIDTH}>
            <PartialBorders>
              <div className={CENTER_FLEX_ITEMS}>
                <RunningNumberBox
                  value={monthlyCost}
                  title={
                    <>
                      Cost <span className={GRAY_SIDE_TEXT}>(monthly)</span>
                    </>
                  }
                  valueDataTestId="top-unevictable-monthly-cost-value"
                  numberVariant="h2"
                  prefix="$"
                  numberClassName="text-text-lightBlack"
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PartialBorders left>
              <div className={CENTER_FLEX_ITEMS}>
                <RunningNumberBox
                  title={
                    <>
                      Blocked Nodes <span className={GRAY_SIDE_TEXT}>(unevictable)</span>
                    </>
                  }
                  valueDataTestId="top-unevictable-blocked-nodes"
                  value={blockedNodes}
                  numberVariant="h2"
                  numberClassName="text-text-lightBlack"
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PartialBorders left right>
              <div className={CENTER_FLEX_ITEMS}>
                <RunningNumberBox
                  title="Wasted spend"
                  valueDataTestId="top-unevictable-wasted-spend-value"
                  value={wastedSpend}
                  suffix="%"
                  numberVariant="h2"
                  numberClassName={clsx({ "text-main-red": wastedSpend > 0, "text-text-lightBlack": wastedSpend <= 0 })}
                  className={clsx(MAIN_GAP)}
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
                <WasteVsOptimizedBarCharts
                  waste={wastedSpend}
                  optimized={100 - wastedSpend}
                  disableTooltip={wastedSpend <= 0}
                  disableAnimation={disableAnimation}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PercentagePieChart
              xLabel="optimized"
              yLabel="un-optimized"
              title="Optimized"
              subtitle="pods without owner"
              xValue={totalOptimizedPods}
              yValue={totalUnOptimizedPods}
              showLabels
              disableAnimation={disableAnimation}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default TopSection;
