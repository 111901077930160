import { MenuItem, Select, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { components } from "../../api/schema";
import { SCALEOPS_COLORS } from "../../colors";
import DownIcon from "../../Icons/DownIcon";
import MagicWand from "../../Icons/MagicWand";
import useResetRecommendation from "../../pages/Overview/PolicyTuning/mutation/useResetRecommendation";
import PolicyNameTooltip from "../../pages/Overview/PolicyTuning/PolicyNameTooltip";
import { useWorkloadsContext } from "../../WorkloadsContext";
import Tooltip from "../Tooltip";
import useAttachPolicyToWorkload from "./useAttachPolicyToWorkload";

interface Props {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
  overriddenWorkloadsIds: string[];
  isReadOnlyFrontEnd: boolean;
  policyNames: string[];
}

const PolicyCell = ({ params, overriddenWorkloadsIds, isReadOnlyFrontEnd, policyNames }: Props) => {
  const isEditable = params.row.isEditable && params.row.namespace !== "scaleops-system";
  const attachPolicyToWorkload = useAttachPolicyToWorkload();
  const resetRecommendation = useResetRecommendation();
  const { deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  const [displayPolicyName, setDisplayPolicyName] = useState<string>(
    params.row.displayPolicyName && !overriddenWorkloadsIds.includes(params.row.id)
      ? params.row.displayPolicyName
      : params.row.policyName
  );
  const smartPolicyName = params.row.smartPolicyName;

  useEffect(() => {
    let policy = "";
    switch (true) {
      case params.row.displayPolicyName &&
        params.row.displayPolicyName?.length > 0 &&
        !overriddenWorkloadsIds.includes(params.row.id):
        policy = params.row.displayPolicyName;
        break;
      case params.row.policyName && params.row.policyName?.length > 0:
        policy = params.row.policyName;
        break;
      default:
        break;
    }

    if (policy !== displayPolicyName) setDisplayPolicyName(policy);
  }, [params.row.displayPolicyName, params.row.policyName]);

  const isSmartPolicy = params.row.smartPolicyName === displayPolicyName;

  return (
    <div className="w-full px-2">
      <Select
        disabled={isReadOnlyFrontEnd || !isEditable || params.row?.isPolicyForced}
        value={displayPolicyName}
        sx={{
          width: "100%",
          color: isSmartPolicy ? "black" : undefined,
          background: isSmartPolicy ? SCALEOPS_COLORS.guideline.lightPurple : SCALEOPS_COLORS.guideline.lightBlue,
          borderRadius: ".875rem",
          borderColor: SCALEOPS_COLORS.border,
          "& .MuiInputBase-input": {
            padding: ".25rem .875rem",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            padding: "0rem",
          },
          "& .MuiSelect-icon": {
            width: ".75rem",
            height: ".75rem",
            marginTop: ".125rem",
          },
        }}
        onChange={(e) => {
          deleteOverriddenWorkloadsProperties({
            ids: [params.row.id],
            propertyNames: ["policyName"],
          });
          const updateValue = async () => {
            attachPolicyToWorkload.mutate({
              name: e.target.value,
              target: `${params.row.type}-${params.row.workloadName}`.toLocaleLowerCase(),
              namespaceName: params.row.namespace,
              id: params.row.id,
              oldPolicyName: params.row.policyName,
            });

            await resetRecommendation.mutateAsync({
              name: `${params.row.type.toLowerCase()}-${params.row.workloadName}`,
              namespace: params.row.namespace,
              id: params.row.id,
              dontShowToast: true,
            });
          };
          updateValue();
          setDisplayPolicyName(e.target.value);
        }}
        renderValue={() => {
          return (
            <Tooltip
              title={
                <PolicyNameTooltip
                  displayPolicyName={displayPolicyName}
                  namespace={params.row.namespace}
                  smartPolicyName={params.row.smartPolicyName}
                  smartPolicyWorkloadType={params.row.smartPolicyWorkloadType}
                  isPolicyForced={params.row.isPolicyForced}
                />
              }
              maxWidth={500}
            >
              <Typography variant="body2" fontWeight={500} className="w-full pt-[.0625rem] flex items-center gap-2">
                {params.row.smartPolicyName == displayPolicyName && (
                  <MagicWand width={14} height={14} className="text-guideline-darkBlue" />
                )}
                <p className="truncate">{displayPolicyName}</p>
              </Typography>
            </Tooltip>
          );
        }}
        IconComponent={DownIcon}
      >
        {policyNames.map((policyName) => (
          <MenuItem key={policyName} value={policyName}>
            <Typography variant="body2" fontWeight={500} className="flex items-center gap-2">
              {policyName}
              {smartPolicyName === policyName && (
                <div className="flex gap-2 bg-guideline-lightPurple px-2 py-1 rounded-full items-center ml-2">
                  <MagicWand color="black" width={18} height={18} />
                  <p className="text-black text-[.75rem] bolder font-light">Auto-Detected</p>
                </div>
              )}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PolicyCell;
