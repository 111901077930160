import { DataGrid, GridColDef, GridRenderCellParams, GridSortDirection } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetAggregations, GetAggregationsResponse } from "../../../../api/fetcher";
import { components } from "../../../../api/schema";
import { ASC, DESC } from "../../../../components/WorkloadStatusByNamespace/utils";
import { getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../../utils/useStateWithLocalStorage";
import { ColumnNames, Field, getSharedColumns, getSortedColumn } from "../utils";
import ExportMenu from "./ExportCSV/ExportMenu";
import useAggregationFilters from "./hooks/useAggregationFilters";
import WorkloadName from "./WorkloadName";
import { getAggregationValuesAsString } from "../../../../components/WorkloadsAggregation/utils";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";

const COST_PAGE_SIZE_LOCAL_STORAGE_KEY_AGGREGATION = "COST_PAGE_SIZE_LOCAL_STORAGE_KEY_AGGREGATION";

const INITIAL_SORT_MODEL = [
  {
    field: String(Field.totalCost),
    sort: DESC as GridSortDirection,
  },
];

const getColumns = (selectedColumns: (string | undefined)[]): GridColDef[] => [
  {
    field: Field.id,
    headerName: ColumnNames[Field.id],
    hide: !selectedColumns.includes(Field.id),
    flex: 3,
    minWidth: 350,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      return <WorkloadName row={params.row} />;
    },
  },
  ...getSharedColumns(selectedColumns, "Number of Pods"),
];

interface Props {
  selectedColumns: (string | undefined)[];
  isGpu: boolean;
}

const { queryKey, queryFn } = GetAggregations();

const AggregationTable = ({ selectedColumns, isGpu }: Props) => {
  const filters = useAggregationFilters();
  const [searchTerm] = useQueryParam("searchTerms", StringParam);
  const [rows, setRows] = useState<GetAggregationsResponse["aggregatedWorkloads"]>([]);

  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);

  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: COST_PAGE_SIZE_LOCAL_STORAGE_KEY_AGGREGATION,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });

  const [sortField, setSortField] = useQueryParam("sortField", withDefault(StringParam, String(Field.totalCost)));
  const [sortDirection, setSortDirection] = useQueryParam("sortDirection", withDefault(StringParam, DESC));
  const enableHpaOptimization = useHpaOptimizationEnabled();

  const { data, isLoading, error, isError } = useQuery<GetAggregationsResponse, Error>({
    queryKey: [queryKey, filters, isGpu],
    queryFn: () =>
      queryFn({
        ...filters,
        gpuWorkloadsOnly: isGpu,
        multiCluster: true,
      }),
    enabled: Object.keys(filters).length > 0,
  });

  useEffect(() => {
    if (data?.aggregatedWorkloads) {
      // get the sliced
      let sortedData = data.aggregatedWorkloads.map((wl) => {
        return {
          ...wl,
          savingsAvailable: enableHpaOptimization ? wl.savingsAvailableWithReplicas : wl.savingsAvailable,
        };
      });
      if (searchTerm) {
        sortedData = sortedData?.filter((aggregation) => {
          const name = getAggregationValuesAsString(aggregation);
          return name.includes(searchTerm);
        });
      }
      if (sortField && sortDirection) {
        // sort by displayWorkloadName if sortField is workloadName
        if (sortField === Field.id) {
          sortedData = sortedData.sort((a, b) => {
            if (sortDirection === ASC) {
              return a.id.localeCompare(b.id);
            } else {
              return b.id.localeCompare(a.id);
            }
          });
        }
      }
      sortedData = getSortedColumn({ sortField, sortDirection, sortedData });
      sortedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];
      setRows(sortedData);
      setRowCount(sortedData?.length || 0);
    }
  }, [data, page, pageSize, sortField, sortDirection, searchTerm, isGpu, enableHpaOptimization]);

  if (isError) {
    console.log("Error fetching aggregations", error);
    return null;
  }
  return (
    <div className="flex flex-col gap-3">
      <DataGrid
        pagination={true}
        headerHeight={HEADER_HEIGHT}
        autoHeight={true}
        sx={getDataGridSx(false, false)}
        rowHeight={ROW_HEIGHT}
        columns={getColumns(selectedColumns)}
        rows={rows}
        initialState={{
          pagination: {
            pageSize: pageSize,
          },
        }}
        pageSize={pageSize}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={(x) => {
          if (isInitialSortModel) setIsInitialSortModel(false);
          setSortField(x[0]?.field);
          setSortDirection(x[0]?.sort);
        }}
        sortModel={
          isInitialSortModel && !sortField && !sortDirection
            ? INITIAL_SORT_MODEL
            : [
                {
                  field: String(sortField),
                  sort: String(sortDirection) as GridSortDirection,
                },
              ]
        }
        rowCount={rowCount}
        disableSelectionOnClick
      />
      <ExportMenu isGpu={isGpu} />
    </div>
  );
};

export default AggregationTable;
