import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMainContext } from "../MainContext";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../api/fetcher";
import TopOverviewSection from "../pages/Overview/TopOverviewSection/TopOverviewSection";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import useFilterQueryParams, { FilterParamObject } from "./WorkloadStatusByNamespace/useFilterQueryParams";
import useHpaOptimizationEnabled from "./WorkloadStatusByNamespace/useHpaOptimizationEnabled";

import { enableFilterByUrlParam, FilterByUrlParam } from "../utils/queryParams";
import { ScaleOpsProduct } from "../utils/typesUtils";

const DEFAULT_FETCH_INTERVAL = 12000;
const DEMO_FETCH_INTERVAL = 5000;

interface Props {
  disableAnimation?: boolean;
  scaleOpsProduct?: ScaleOpsProduct;
}

const ENABLE_HPA_RECOMMENDATION = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION);

const TopOverviewContainer = ({ disableAnimation, scaleOpsProduct }: Props) => {
  const [refetchInterval, setRefetchInterval] = useState<number>(DEFAULT_FETCH_INTERVAL);
  const dashboardByNamespace = getDashboardByNamespace();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();
  const { didClusterChanged } = useMainContext();
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();

  useEffect(() => {
    if (FeatureEnabled("DemoVersion")) {
      setRefetchInterval(DEMO_FETCH_INTERVAL);
    }
  }, []);

  const { data, isLoading, error } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filterQueryParams],
    queryFn: () => dashboardByNamespace.queryFn(filterQueryParams),
    refetchInterval,
  });

  if (error && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(error);
  }

  return (
    <TopOverviewSection
      monthlyForecast={data?.totalNamespaceSummary?.totalNodesCost}
      defaultOptimizedCost={
        hpaOptimizationEnabled || ENABLE_HPA_RECOMMENDATION
          ? data?.totalNamespaceSummary?.optimizedWithReplicas ?? data?.totalNamespaceSummary?.optimized
          : data?.totalNamespaceSummary?.optimized
      }
      underProvisionedWorkloads={data?.totalNamespaceSummary?.underProvisionedWorkloads}
      overProvisionedWorkloads={data?.totalNamespaceSummary?.overProvisionedWorkloads}
      totalAutomatedWorkloads={data?.totalNamespaceSummary?.automatedWorkloads}
      totalUnAutomatedWorkloads={data?.totalNamespaceSummary?.unautomatedWorkloads}
      monthlySavingsAvailable={
        hpaOptimizationEnabled || ENABLE_HPA_RECOMMENDATION
          ? data?.totalNamespaceSummary?.monthlySavingsAvailableWithReplicas ??
            data?.totalNamespaceSummary?.monthlySavingsAvailable
          : data?.totalNamespaceSummary?.monthlySavingsAvailable
      }
      activeMonthlySavingsAvailable={
        hpaOptimizationEnabled || ENABLE_HPA_RECOMMENDATION
          ? data?.totalNamespaceSummary?.activeMonthlySavingsWithReplicas ??
            data?.totalNamespaceSummary?.activeMonthlySavingsAvailable
          : data?.totalNamespaceSummary?.activeMonthlySavingsAvailable
      }
      disableAnimation={disableAnimation}
      totalUnoptimizedNotEvictableWorkloads={data?.totalNamespaceSummary?.totalUnoptimizedNotEvictableWorkloads}
      isLoading={isLoading}
      scaleOpsProduct={scaleOpsProduct}
    />
  );
};

export default TopOverviewContainer;
