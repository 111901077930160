import { Typography } from "@mui/material";
import { Action } from "./Utils";

export const ActionCell = ({ icon, title, description }: Action) => {
  return (
    <div className="flex gap-3 items-center">
      <div className="w-[2.8rem] h-[2.8rem] rounded-full bg-primary-purpleBlue flex justify-center items-center">
        {icon}
      </div>
      <div>
        <Typography variant="h6" fontWeight={700}>
          {title}
        </Typography>
        <Typography variant="body2" className="text-text-lightBlack">
          {description}
        </Typography>
      </div>
    </div>
  );
};
