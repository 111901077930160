import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Tab from "../../../components/Tab";
import { CostReportType } from "../utils";
import AggregationFilters from "./Aggregation/AggregationFilters";
import AggregationTable from "./Aggregation/AggregationTable";
import NetworkAggregationTable from "./NetworkAggregation/NetworkAggregationTable";
import NetworkWorkloadsTable from "./NetworkWorkloads/NetworkWorkloadsTable";
import { Field, TableType } from "./utils";
import WorkloadsFilters from "./Workloads/WorkloadsFilters";
import WorkloadsTable from "./Workloads/WorkloadsTable";
import Tooltip from "../../../components/Tooltip";
import InfoIcon from "../../../Icons/InfoIcon";

interface Props {
  selectedTable: TableType;
  setSelectedTable: (table: TableType) => void;
  reportType: CostReportType;
  isMultiCluster?: boolean;
}

const columnOptions = (tableType: TableType, reportType: CostReportType) => {
  if (reportType === CostReportType.Network) {
    return [
      Field.clusterName,
      Field.totalCost,
      Field.crossAZCost,
      Field.totalDataTransfer,
      Field.crossAZDataTransfer,
      Field.intraAZDataTransfer,
    ];
  }

  return [Field.clusterName, Field.totalCost]
    .concat(reportType !== CostReportType.Gpu ? Field.savingsAvailable : [])
    .concat([Field.spot, Field.onDemand, Field.cpu, Field.memory])
    .concat(
      reportType === CostReportType.Gpu
        ? [
            Field.gpu,
            Field.avgGpuUtilization,
            Field.avgGpuMemory,
            Field.avgGpuMemoryUtilization,
            Field.gpuIdleTimeRatio,
          ]
        : []
    )
    .concat([Field.replicas])
    .concat(tableType === TableType.Workloads ? [Field.workloadName] : []);
};

const useWorkloadColumns = (tableType: TableType, reportType: CostReportType) => {
  const [selectedAggregationColumns, setSelectedAggregationColumns] = useState<(string | undefined)[]>();
  const [selectedWorkloadsColumns, setSelectedWorkloadsColumns] = useState<(string | undefined)[]>([]);
  const [networkColumns, setNetworkColumns] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    const columns = [Field.id, Field.clusterName, Field.totalCost];

    if (reportType === CostReportType.Gpu) {
      columns.push(Field.gpu, Field.avgGpuMemoryUtilization, Field.avgGpuUtilization, Field.gpuIdleTimeRatio);
    }

    if (reportType === CostReportType.Compute) {
      columns.push(Field.savingsAvailable, Field.spot, Field.onDemand, Field.cpu, Field.memory);
    }

    setSelectedAggregationColumns(columns);
  }, [reportType]);

  useEffect(() => {
    const columns = [Field.workloadName, Field.clusterName, Field.totalCost];

    if (reportType === CostReportType.Gpu) {
      columns.push(Field.gpu, Field.avgGpuMemoryUtilization, Field.avgGpuUtilization, Field.gpuIdleTimeRatio);
    }

    if (reportType === CostReportType.Compute) {
      columns.push(Field.savingsAvailable, Field.spot, Field.onDemand, Field.cpu, Field.memory);
    }

    setSelectedWorkloadsColumns(columns);
  }, [reportType]);

  useEffect(() => {
    setNetworkColumns([
      tableType === TableType.Workloads ? Field.workloadName : Field.id,
      Field.clusterName,
      Field.totalCost,
      Field.crossAZCost,
    ]);
  }, [tableType]);

  if (reportType === CostReportType.Network) {
    return {
      columns: networkColumns,
      setColumns: setNetworkColumns,
    };
  } else {
    if (tableType === TableType.Workloads) {
      return {
        columns: selectedWorkloadsColumns,
        setColumns: setSelectedWorkloadsColumns,
      };
    } else {
      return {
        columns: selectedAggregationColumns,
        setColumns: setSelectedAggregationColumns,
      };
    }
  }
};

const TablesContainer = ({ selectedTable, setSelectedTable, reportType, isMultiCluster }: Props) => {
  const { columns, setColumns } = useWorkloadColumns(selectedTable, reportType);
  const [, setSearchTerm] = useQueryParam("searchTerms", StringParam);

  const FiltersComponent = selectedTable === TableType.Workloads ? WorkloadsFilters : AggregationFilters;
  const TableComponent =
    reportType === CostReportType.Network
      ? selectedTable === TableType.Workloads
        ? NetworkWorkloadsTable
        : NetworkAggregationTable
      : selectedTable === TableType.Workloads
      ? WorkloadsTable
      : AggregationTable;

  return (
    <div className="bg-white rounded-lg rounded-tl-none relative top-[36px] p-8 flex flex-col gap-4">
      <div className="flex absolute right-0 top-[-36px] w-full">
        {Object.entries(TableType).map(([key, value]) => {
          const isGpuWorkloadsTab = key === TableType.Workloads && reportType === CostReportType.Gpu;

          const displayName = isGpuWorkloadsTab ? (
            <span className="flex">
              {value}{" "}
              <Tooltip title={"Workloads with GPU requests"} className="ml-1 mt-0.5">
                <InfoIcon width={14} height={14} />
              </Tooltip>
            </span>
          ) : (
            value
          );

          return (
            <Tab
              key={key}
              isSelected={selectedTable === value}
              onClick={() => {
                setSearchTerm("");
                setSelectedTable(value);
              }}
              name={displayName}
              dataTestId={`cost-page-${key}-tab`}
            />
          );
        })}
      </div>

      <FiltersComponent
        selectedColumns={columns ?? []}
        setSelectedColumns={setColumns}
        columnOptions={columnOptions(selectedTable, reportType)}
        isMultiCluster={isMultiCluster}
      />
      <TableComponent selectedColumns={columns ?? []} isGpu={reportType === CostReportType.Gpu} />
    </div>
  );
};

export default TablesContainer;
