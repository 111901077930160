import ActiveIssuesAlerts from "./ActiveIssuesAlerts";
import AvailableSavingsAlerts from "./AvailableSavingsAlerts";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";

const SnapshotAlertBar = () => {
  if (!IsSnapshotServer()) {
    return null;
  }
  return (
    <div className={"flex flex-col fixed top-0 right-0 z-[1500] w-auto h-auto p-5 gap-2.5"}>
      <ActiveIssuesAlerts />
      <AvailableSavingsAlerts />
    </div>
  );
};

export default SnapshotAlertBar;
