import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetUnevictableData, GetUnevictableDataResponse } from "../../api/fetcher";
import AdvancedSettingsIcon from "../../Icons/AdvancedSettingsIcon";
import BinPackingIcon from "../../Icons/BinPackingIcon";
import DisableIcon from "../../Icons/DisableIcon";
import MultipleCubesIcon from "../../Icons/MultipleCubesIcon";
import TopSection from "./TopSection";
import UnevictableTable from "./UnevictableTable";
import { UevictableTableRowType } from "./Utils";

const { queryFn, queryKey } = GetUnevictableData();
const ICON_SIZE = 29;
const ICON_CLASS_NAME = "text-white fill-current m-2";
const ICON_PROPS = { width: ICON_SIZE, height: ICON_SIZE, className: ICON_CLASS_NAME };

const actions = {
  unevictableWorkloads: {
    title: "Automate unevictable workloads",
    description: "Optimized your unevictable and unhealthy pods",
    icon: <MultipleCubesIcon {...ICON_PROPS} />,
  },
  ownerlessWorkloads: {
    title: "Automate pods without owner",
    description: "Optimization will be applied upon pod creation",
    icon: <BinPackingIcon {...ICON_PROPS} />,
  },
  unhealthyWorkloads: {
    title: "Automate un-ready workloads",
    description: "Un-ready workloads experience liveness and availability issues",
    icon: <DisableIcon {...ICON_PROPS} />,
  },
  kubeSystemWorkloads: {
    title: "Optimize kube-system pods",
    description: "Optimization will be applied upon pod creation",
    icon: <AdvancedSettingsIcon {...ICON_PROPS} />,
  },
};

export default function Unevictable() {
  const [rows, setRows] = useState<UevictableTableRowType[]>([]);
  const { data, isLoading, isError, error } = useQuery<GetUnevictableDataResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  useEffect(() => {
    if (data) {
      const unevictableWorkloads = {
        id: "unevictableWorkloads",
        action: actions["unevictableWorkloads"],
        ...data.unevictableWorkloads,
      };
      const ownerlessWorkloads = {
        id: "ownerlessWorkloads",
        action: actions["ownerlessWorkloads"],
        ...data.ownerlessWorkloads,
      };
      const unhealthyWorkloads = {
        id: "unhealthyWorkloads",
        action: actions["unhealthyWorkloads"],
        ...data.unhealthyWorkloads,
      };
      const kubeSystemWorkloads = {
        id: "kubeSystemWorkloads",
        action: actions["kubeSystemWorkloads"],
        ...data.kubeSystemWorkloads,
      };

      setRows([unevictableWorkloads, ownerlessWorkloads, unhealthyWorkloads, kubeSystemWorkloads]);
    }
  }, [data]);

  if (isLoading || !data) {
    return (
      <div className="w-full h-[80vh] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  return (
    <>
      <div className="p-6">
        <TopSection
          monthlyCost={data.cost ?? 0}
          totalOptimizedPods={data.optimizedPods ?? 0}
          totalUnOptimizedPods={data.totalPods ?? 0}
          wastedSpend={data.wastedSpend ?? 0}
          blockedNodes={data.blockedNodes ?? 0}
          disableAnimation={false}
          isLoading={isLoading}
        />
      </div>
      <div className="flex px-8">Charts Here (TODO) {"😍"}</div>
      <div className="p-8">
        <UnevictableTable rows={rows} isLoading={false} />
      </div>
    </>
  );
}
