import { Box, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import CodeSnippet, { THEME } from "../../../components/CodeSnippet";
import GpuIcon from "../../../Icons/GpuIcon";
import useGetDcgmExporterInfo from "./useGetDcgmExporterInfo";

const ENABLE_GPU_BANNER = true;

const ICON_SIZE = 40;

export default function GpuBanner({ className }: { className?: string }) {
  const { data, isLoading, isError, error } = useGetDcgmExporterInfo();

  if (isError) {
    console.error(error);
    return null;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (data?.dcgmExporterFound || !ENABLE_GPU_BANNER) {
    return null;
  }

  return (
    <div className={clsx("flex flex-col gap-3", className)}>
      <div className="flex gap-2 items-center">
        <GpuIcon width={ICON_SIZE} height={ICON_SIZE} />
        <div>
          <Typography variant="body1" fontWeight="bold">
            Install Nvidia's DCGM Exporter
          </Typography>
          <Typography variant="body2">
            Your cluster does not have Nvidia's DCGM Exporter installed. GPU insights are not available.
          </Typography>
        </div>
      </div>
      <Box>
        <Typography variant="body2">
          In order to enable it please <strong>run the following commands:</strong>
        </Typography>{" "}
        <CodeSnippet
          codeSnippet={`
helm repo update scaleops
helm show crds scaleops/scaleops | kubectl apply -f -
helm get values scaleops -n scaleops-system -oyaml | \\
helm upgrade scaleops scaleops/scaleops -n scaleops-system --set global.enableDcgmExporter=true -f -
`}
          theme={THEME.light}
          className="w-fit"
        />
        <span className="text-[10px] italic text-text-darkGray">
          Note: This operation will <b>install a DaemonSet</b>.
        </span>
      </Box>
    </div>
  );
}
