import { SCALEOPS_COLORS } from "../colors";

export const RED_STRIPES_PATTERN_UNIQUE_ID = "color-stripe";

export const RedStripesPattern = () => (
  <pattern
    id={RED_STRIPES_PATTERN_UNIQUE_ID}
    width="8"
    height="8"
    patternUnits="userSpaceOnUse"
    patternTransform="rotate(45)"
  >
    <rect width="2" height="8" fill={SCALEOPS_COLORS.main.red} />
  </pattern>
);

export default RedStripesPattern;
