import { components } from "../../../api/schema";
import SwitchBox, { THEMES } from "../../../components/SwitchBox";
import Tooltip from "../../../components/Tooltip";
import AutomationButtonDisabledTooltip from "../../../components/WorkloadStatusByNamespace/Columns/AutomationButtonDisabledTooltip";
import useIsAutomationButtonDisabled from "../../../components/WorkloadStatusByNamespace/Columns/useIsAutomationButtonDisabled";
import useUpdateAutomation from "./mutation/useUpdateAutomation";

interface Props {
  isAutomate: boolean;
  setIsAutomate: (isAutomate: boolean) => void;
  fetchWorkloads: () => void;
  selectedWorkload: components["schemas"]["UtilsWorkload"];
}

const AutomationSwitch = ({ isAutomate, setIsAutomate, fetchWorkloads, selectedWorkload }: Props) => {
  const isAutomationButtonDisabled = useIsAutomationButtonDisabled({ params: selectedWorkload });

  const updateAutomation = useUpdateAutomation(fetchWorkloads);

  const handleClick = () => {
    updateAutomation.mutate({
      id: selectedWorkload.id,
      namespace: selectedWorkload.namespace,
      workloadName: selectedWorkload.workloadName,
      workloadType: selectedWorkload.type,
      state: !isAutomate,
    });
    setIsAutomate(!isAutomate);
  };

  const isAutomationExcluded = selectedWorkload.isAutomationExcluded;

  return (
    <Tooltip
      title={<AutomationButtonDisabledTooltip params={selectedWorkload} />}
      maxWidth={500}
      disabled={!isAutomationButtonDisabled}
    >
      <SwitchBox
        title="Automate"
        handleClick={handleClick}
        checked={isAutomate}
        disabled={isAutomationButtonDisabled}
        notAllowed={isAutomationExcluded}
        theme={THEMES.Purple}
      />
    </Tooltip>
  );
};

export default AutomationSwitch;
