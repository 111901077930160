import { useQuery } from "@tanstack/react-query";
import { GetActiveIssues, GetActiveIssuesResponse } from "../../api/fetcher";
import AlertBar, { AlertBarTheme } from "../AlertBar";

const ActiveIssuesAlerts = () => {
  const { queryFn, queryKey } = GetActiveIssues();
  const { data } = useQuery<GetActiveIssuesResponse, Error>({
    queryKey: [queryKey],
    queryFn,
    refetchInterval: 1000 * 60,
  });
  const hasActiveIssues = !!data && Object.keys(data).length > 0;
  return (
    (hasActiveIssues && (
      <>
        {!!data?.missingNodeGroups && (
          <AlertBar
            title={"Active Issue"}
            customDescription={"Missing node group"}
            theme={AlertBarTheme.LightRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-2xl"}
          />
        )}
        {!!data?.frequentClusterEvictions && (
          <AlertBar
            title={"Active Issue"}
            customDescription={"Frequent cluster evictions"}
            theme={AlertBarTheme.LightRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-2xl"}
          />
        )}
        {!!data?.frequentWorkloadEvictions && (
          <AlertBar
            title={"Active Issue"}
            customDescription={"Frequent workload evictions"}
            theme={AlertBarTheme.LightRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-2xl"}
          />
        )}
        {!!data?.unstableRecommendation && (
          <AlertBar
            title={"Active Issue"}
            customDescription={"Unstable recommendation"}
            theme={AlertBarTheme.LightRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-2xl"}
          />
        )}
        {!!data?.misconfiguredAutomatedHPA && (
          <AlertBar
            title={"Active Issue"}
            customDescription={"Misconfigured automated HPA"}
            theme={AlertBarTheme.LightRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-2xl"}
          />
        )}
        {!!data?.nodeLifecycleTypeUnknown && (
          <AlertBar
            title={"Active Issue"}
            customDescription={"Undetectable node lifecycle"}
            theme={AlertBarTheme.LightRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-2xl"}
          />
        )}
      </>
    )) ||
    null
  );
};

export default ActiveIssuesAlerts;
